import React, { useState } from "react";
import { AbsoluteWrapper, Container } from "@util/standard";
import { Maybe, SanityProduct } from "@graphql-types";
import styled from "styled-components";
import { BOORSOK_FONT, FONT_BODY } from "@util/constants";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Controller, EffectCoverflow } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-coverflow";

import { SnacklesSwiperContainer } from "./SnacklesCarouselStyles";

//@ts-ignore
import leftArrow from "@static/assets/snackles/left-arrow.svg";
//@ts-ignore
import rightArrow from "@static/assets/snackles/right-arrow.svg";

//@ts-ignore
import { CircleArrow } from "@util/assets";
// @ts-ignore
import { mainData } from "../../locales/dataConst";
interface Props {
  data: Maybe<Maybe<SanityProduct>[]> | undefined;
  pageIban: Maybe<string> | undefined;
}

const NavWrapper = styled(AbsoluteWrapper)`
  display: flex;
  cursor: pointer;
  z-index: 11;
  top: 50%;
  transform: translateY(-50%);
`;

function SnacklesCarousel({ data, pageIban }: Props) {
  const [controller, setController] = useState<SwiperCore>();
  const [secondaryController, setSecondaryController] = useState<SwiperCore>();

  const mainPageData = mainData[pageIban];
  const { swiperData } = mainPageData;

  if (data == null) return null;

  return (
    <>
      <SnacklesSwiperContainer>
        <div className="swiper-overlay-text">{mainPageData.sliderTitle}</div>

        {controller && (
          <div style={{ position: "relative" }}>
            <NavWrapper left="3%" onClick={() => controller?.slidePrev()}>
              <CircleArrow customArrow flip />
            </NavWrapper>
            <NavWrapper right="3%" onClick={() => controller?.slideNext()}>
              <CircleArrow customArrow />
            </NavWrapper>
            <div className="swiper-sub-container" style={{}}>
              <Swiper
                modules={[EffectCoverflow, Controller]}
                onSwiper={setSecondaryController}
                controller={{ control: controller }}
                onClick={(swiper: Swiper) => {
                  const clickedIndex = swiper.clickedIndex;
                  if (clickedIndex !== undefined) {
                    controller.slideTo(clickedIndex);
                  }
                }}
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={0.8}
                spaceBetween={60}
                coverflowEffect={{
                  rotate: 0.1,
                  stretch: 0,
                  depth: 500,
                  modifier: 1,
                  slideShadows: false,
                }}
                loop={true}
                loopedSlides={swiperData.length}
                breakpoints={{
                  320: {
                    slidesPerView: 1.5,
                    spaceBetween: 100,
                  },
                  576: {
                    slidesPerView: 1.5,
                  },
                  768: {
                    slidesPerView: 2,
                  },
                  1440: {
                    slidesPerView: 2.45,
                  },
                }}
              >
                {swiperData.map(item => {
                  return (
                    <SwiperSlide key={item.id}>
                      <div className="slide-content">
                        <img src={item.swiperImg} alt={item.name} />
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
        )}
        <Container width="100%">
          <Swiper
            modules={[Controller]}
            onSwiper={setController}
            controller={{ control: secondaryController }}
            loop
            slidesPerView={1}
            loopedSlides={swiperData.length}
          >
            {swiperData.map(item => {
              return (
                <SwiperSlide key={item.id}>
                  <div className="secondary-slide-container">
                    <div className="slider-title-container">{item.name}</div>
                    <div className="slider-text-container">
                      <div>
                        <span className="slider-text-content">{item.bdayTitle}</span>
                        <p className="slider-text-content">{item.bday}</p>
                      </div>
                      <div>
                        <span className="slider-text-content">{item.hobbyTitle}</span>
                        <p className="slider-text-content">{item.hobby}</p>
                      </div>
                      <div>
                        <span className="slider-text-content">{item.starSignTitle}</span>
                        <p className="slider-text-content">{item.starSign}</p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Container>
      </SnacklesSwiperContainer>
    </>
  );
}
export default SnacklesCarousel;
