import React, { useEffect, useState } from "react";
import styled from "styled-components";
import BlastercornVideo from "./blastercornVideo";
import BlastercornSlider from "@components/blastercorn/blastercornSlider";
import { Maybe, SanityProduct } from "@graphql-types";
import { Container } from "@util/standard";
import { Eurostile_BQ } from "@util/constants";

//@ts-ignore
import xShot from "@static/assets/x-shot.png";
//@ts-ignore
import blaster from "@static/assets/main-blastercorn.png";
//@ts-ignore
import blasterMobile from "@static/assets/main-blastercorn-mobile.png";
//@ts-ignore
import blastercornCombo from "@static/assets/blastercornCombo.png";

interface Props {
  data: Maybe<Maybe<SanityProduct>[]> | any;
}

const BlastercornContentContainer = styled.div`
  width: 100%;
  height: 100%;
  .blastercorn-content-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin: 0 auto;
    width: 100%;
    @media (max-width: 576px) {
      flex-direction: column-reverse;
    }
       
  .blastercorn-blaster-content {
    width:50%;
    @media (max-width: 576px) {
      width: 100%;
    }
  }

  .blastercorn-img-wrapper {
    display: flex;
    justify-content: center;
    object-fit: cover;
    height: 100%;
    max-height: 100%;
    width: 50%;
    background: #DDDDE0;
    z-index: -1;
    @media (max-width: 576px) {
      width: 100%;
      background: none;
    }
    &:before {   
      @media (max-width: 576px) {
        width: 100%;
        height: 100%;
        margin-top: 32px;
        content: "";
        display: block; 
        position: absolute;
        height: 125%;
        background: #DDDDE0;
        z-index: -1;
      }
     }
     img { 
      max-width: none;
      width: 100%;
    }
  }

  .instanity-blaster-content-container {
    margin: 0px 40px 60px 90px;
    @media (max-width: 1280px) {
      margin: 0px 20px 60px 130px;
    }
    @media (max-width: 991px) {
      margin: 0px 40px 30px 80px;
    }
    @media (max-width: 768px) {
      margin-top: 30px;
    }
    @media (max-width: 576px) {
      margin: 44px 24px;
    }
    .blaster-title h1 {
    font-family: ${Eurostile_BQ};
    font-style: normal;
    font-weight: 500;
    font-size: 52px;
    line-height: 95%;
    text-transform: uppercase;
    color: #000000;
    margin-bottom: 38px;
      @media (max-width: 1024px) {
        font-size: 42px;
      }
      @media (max-width: 991px) {
        font-size: 32px;
      }
      @media (max-width:567px) {
        margin-bottom: 22px;
      }
  }
    .blaster-description p {
      @media (max-width: 991px) {
        line-height: 140%;
        font-size: 16px;
      }
      @media (max-width: 567px) {
        line-height: 160%;
        font-size: 14px;
      }
    }
  }
}
`;
const BlastercornContainer = styled.div`
  min-height: 100vh;
  position: relative;
  width: 100%; 
  background-color: rgb(27, 27, 28);
  height: 56.25vw;
`;

function BlastercornPage({ data }: Props) {
  if (data == null) return null;

  const [width, setWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize);
    }

    setWidth(window.innerWidth);

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, []);


  const videoUrl = "https://player.vimeo.com/video/837809284?background=1";

  return (
    <>
      <BlastercornSlider data={data} />
      <BlastercornContentContainer>
        <Container width="100%" height="100%">
          
        </Container>
      </BlastercornContentContainer >
    </>
  )
}
export default BlastercornPage;