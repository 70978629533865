import React, { useEffect, useState } from "react";
import styled from "styled-components";
import InsanityVideo from "./insanityVideo";
import InsanitySlider from "@components/insanity/insanitySlider";
import { Maybe, SanityProduct } from "@graphql-types";
import { Container } from "@util/standard";
import { Eurostile_BQ } from "@util/constants";

//@ts-ignore
import xShot from "@static/assets/x-shot.png";
//@ts-ignore
import blaster from "@static/assets/main-blaster.png";
//@ts-ignore
import blasterMobile from "@static/assets/main-blaster-mobile.png";
//@ts-ignore
import insanityCombo from "@static/assets/insanityCombo.png";

interface Props {
  data: Maybe<Maybe<SanityProduct>[]> | any;
}

const InsanityContentContainer = styled.div`
  width: 100%;
  height: 100%;
  .insanity-content-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin: 0 auto;
    width: 100%;
    @media (max-width: 576px) {
      flex-direction: column-reverse;
    }
       
  .insanity-blaster-content {
    width:50%;
    @media (max-width: 576px) {
      width: 100%;
    }
  }

  .insanity-img-wrapper {
    display: flex;
    justify-content: center;
    object-fit: cover;
    height: 100%;
    max-height: 100%;
    width: 50%;
    background: #DDDDE0;
    z-index: -1;
    @media (max-width: 576px) {
      width: 100%;
      background: none;
    }
    &:before {   
      @media (max-width: 576px) {
        width: 100%;
        height: 100%;
        margin-top: 32px;
        content: "";
        display: block; 
        position: absolute;
        height: 125%;
        background: #DDDDE0;
        z-index: -1;
      }
     }
     img { 
      max-width: none;
      height: 100%;
      @media (max-width: 576px) {
        width: 100%;
      }
    }
  }

  .instanity-blaster-content-container {
    margin: 0px 40px 60px 90px;
    @media (max-width: 1280px) {
      margin: 0px 20px 60px 130px;
    }
    @media (max-width: 991px) {
      margin: 0px 40px 30px 80px;
    }
    @media (max-width: 768px) {
      margin-top: 30px;
    }
    @media (max-width: 576px) {
      margin: 44px 24px;
    }
    .blaster-title h1 {
    font-family: ${Eurostile_BQ};
    font-style: normal;
    font-weight: 500;
    font-size: 52px;
    line-height: 95%;
    text-transform: uppercase;
    color: #000000;
    margin-bottom: 38px;
      @media (max-width: 1024px) {
        font-size: 42px;
      }
      @media (max-width: 991px) {
        font-size: 32px;
      }
      @media (max-width:567px) {
        margin-bottom: 22px;
      }
  }
    .blaster-description p {
      @media (max-width: 991px) {
        line-height: 140%;
        font-size: 16px;
      }
      @media (max-width: 567px) {
        line-height: 160%;
        font-size: 14px;
      }
    }
  }
}
`;
const InsanityContainer = styled.div`
  min-height: 100vh;
  position: relative;
  width: 100%; 
  background-color: rgb(27, 27, 28);
  height: 56.25vw;
`;

function InsanityPage({ data }: Props) {
  if (data == null) return null;

  const [width, setWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize);
    }

    setWidth(window.innerWidth);

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, []);


  const videoUrl = "https://player.vimeo.com/video/837809284?background=1";

  return (
    <>
      <InsanitySlider data={data} />
      <Container width="100%" id="insanity-video" >
        <Container width="100%" height="100%" position="relative">
          <InsanityContainer >
            <InsanityVideo src={videoUrl} />
          </InsanityContainer>
        </Container>
      </Container>
      <InsanityContentContainer>
        <Container width="100%" height="100%">
          <div className="insanity-content-container">
            <div className="insanity-img-wrapper">
              {width >= 767 ? (<img src={blaster} alt="footer" />) : (<img src={blasterMobile} alt="footer-image" />)}
            </div>
            <div className="insanity-blaster-content" lang="{currentLanguage}">
              <div className="instanity-blaster-content-container">
                <div className="blaster-title">
                  <h1>Combine & Make Insane Combos</h1>
                </div>
                <div className="blaster-description">
                  <p>Combine the Motorized Rage Fire, Mad Mega Barrel, Berzerko, and Manic blasters into the most EPICALLY INSANE blaster combo! This modded beast can hold every accessory and dart and makes one mind blowing blaster. The more XSHOT Insanity blasters you have, the more insane this blaster combo gets! </p>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </InsanityContentContainer >
    </>
  )
}
export default InsanityPage;