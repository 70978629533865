import React, { useState, useEffect } from "react";
import styled from "styled-components";
import SnacklesCarousel from "./snacklesCarousel";
import SnacklesSecondaryCarousel from "./snacklesSecondaryCarousel";
import { Maybe, SanityProduct } from "@graphql-types";
import { Container, A } from "@util/standard";
import { isBrowser } from "@util/helper";
import { Hero, Marquee } from "@global";
import {
  Eurostile_BQ,
  INTER_FONT,
  fontWeights,
  LIGHTGREY_COLOR,
  SECONDARY_COLOR,
  BLACK_COLOR,
  PINK_COLOR,
  BOORSOK_FONT,
  FONT_BODY,
} from "@util/constants";

// @ts-ignore
import { mainData } from "../../locales/dataConst";
// @ts-ignore
import snacklesPopupImage from "@static/assets/snackles/snacklesPopupImage.png";
// @ts-ignore
import snacklesCover from "@static/assets/snackles/snackles-cover.png";
//@ts-ignore
import snacklesLogo from "@static/assets/snackles/snackles-logo.png";
//@ts-ignore
import collection from "@static/assets/snackles/collection.png";
//@ts-ignore
import snacklesBottomImg from "@static/assets/snackles/snackles-bottom.png";
//@ts-ignore
import snacklesBottomeImgMobile from "@static/assets/snackles/snackles-bottom-mobile.png";
import { StyledLink } from "src/global/link/link.style";

interface Props {
  data: Maybe<Maybe<SanityProduct>[]> | undefined;
  pageIban: Maybe<string> | undefined;
}
const SnacklesPage = styled(Container)`
  display: block;
  text-transform: uppercase;
  font-family: ${Eurostile_BQ};
  font-weight: ${fontWeights.theme};
  .header-cover-img {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background: rgba(0, 0, 0, 0.15);
      z-index: 1;
    }
  }
  .marquee-bottom-line {
    width: 100%;
    height: 1px;
    background: ${BLACK_COLOR};
  }
`;

const SnacklesContainer = styled.div<{ isScrolled: boolean }>`
  justify-content: space-between;
  font-family: ${BOORSOK_FONT};
  padding: 20px 40px;
  align-items: center;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 11;
  color: #ffffff;
  display: ${({ isScrolled }) => (isScrolled ? "none" : "flex")};
  @media screen and (min-width: 320px) and (max-width: 900px) and (orientation: landscape) {
    font-size: 16px;
  }
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 0.92;
    padding: 24px 10px;
    gap: 25px;
  }
  @media (max-width: 380px) {
    justify-content: space-between;
    flex-wrap: nowrap;
  }
  .snackles-header-text {
    font-size: 18px;
    line-height: 22px;
    font-weight: 400;
    cursor: pointer;

    @media (max-width: 768px) {
      margin: 0 auto;
      text-align: center;
    }
    @media (max-width: 567px) {
      font-size: 12px;
      line-height: 11px;
    }
  }
  .snackles-logo {
    width: 138px;
    height: 66px;
    @media (max-width: 340px) {
      width: 120px;
      height: 48px;
    }
  }
`;

const LinkModelOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: -10;
  opacity: 0;
  transition: opacity 0.6s ease;

  &.open {
    opacity: 1;
    z-index: 98;
  }

  &.close {
    opacity: 0;
    z-index: -10;
  }
`;

const LinkModelPopup = styled.div`
  background: ${PINK_COLOR};
  color: ${LIGHTGREY_COLOR};
  padding: 20px 28px 0;
  font-weight: ${fontWeights.theme};
  font-size: 15.3694px;
  line-height: 28px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -9;
  opacity: 0;
  width: 100%;
  max-width: 336px;
  transition: opacity 0.6s ease, transform 0.6s ease;
  @media (max-width: 320px) {
    padding: 20px 18px 32px;
  }

  &.open {
    z-index: 99;
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
    animation: fadeIn 0.6s ease forwards;
  }

  &.close {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.95);
    transition: opacity 0.6s ease, transform 0.6s ease;
    z-index: -9;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translate(-50%, -50%) scale(0.95);
    }
    to {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }
  }

  .link-model-title {
    font-size: 32px;
    line-height: 120%;
    font-family: ${BOORSOK_FONT};
    color: ${SECONDARY_COLOR};
    margin-bottom: 20px;
    text-align: center;
    text-transform: uppercase;
  }

  .link-model-content {
    font-family: ${INTER_FONT};
    color: ${SECONDARY_COLOR};
    width: 220px;
    margin: auto;
    font-size: 14px;
    line-height: 1.2;
    text-transform: initial;
    text-align: center;
  }

  .link-model-footer {
    display: flex;
    justify-content: space-evenly;
    padding-top: 29px;
  }

  .link-model-footer-item {
    font-family: ${INTER_FONT};
    color: ${SECONDARY_COLOR};
    font-size: 14px;
    line-height: 1.57;
    font-weight: 600;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;

    &:hover {
      transform: scale(1.05);
    }
  }

  .white-border {
    border: 1px solid ${SECONDARY_COLOR};
    border-radius: 30px;
    padding: 8px 14px;
  }

  .link-model-text {
    color: ${SECONDARY_COLOR};
    margin: 0;
  }

  .link-model-footer-img {
    width: 100%;
    height: 100%;
    img {
      display: block;
      opacity: 0;
      animation: fadeInImage 0.1s 0.1s forwards;
    }
  }

  @keyframes fadeInImage {
    to {
      opacity: 1;
    }
  }
`;

const HeaderTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  color: ${SECONDARY_COLOR};
  width: 45%;
  @media (max-width: 991px) {
    width: 80%;
  }
  @media screen and (min-width: 320px) and (max-width: 900px) and (orientation: landscape) {
    top: 80%;
    transform: translate(-50%, -80%);
  }
  @media (max-width: 576px) {
    width: 100%;
  }
  .snakcles-container-text {
    font-family: ${BOORSOK_FONT};
    font-size: 125px;
    line-height: 101%;
    text-align: center;
    @media (max-width: 767px) {
      font-size: 100px;
    }
    @media screen and (min-width: 320px) and (max-width: 900px) and (orientation: landscape) {
      font-size: 72px;
    }
    @media (max-width: 568px) {
      font-size: 64px;
      width: 100%;
    }
  }
  .snackles-container-sub-text {
    background-color: ${SECONDARY_COLOR};
    color: #e4007e;
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
    margin: 50px auto 0;
    border: 1px solid ${SECONDARY_COLOR};
    border-radius: 30px;
    padding: 12px 17px;
    display: inline-block;
    font-family: ${INTER_FONT};
    cursor: pointer;
    border: none;
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
    cursor: pointer;
    &:hover {
      transform: scale(1.05);
      color: white;
      background-color: #e4007e;
    }

    @media screen and (min-width: 320px) and (max-width: 900px) and (orientation: landscape) {
      margin-top: 20px;
    }
  }
`;
const SnacklesFooterContainer = styled(Container)`
  margin: 0 auto 0;
  padding-top: 133px;
  background-color: rgb(228, 0, 126);
  @media only screen and (max-width: 576px) {
    margin: 0 auto 0;
    padding-top: 48px;
  }
  .snackles-footer-content-container {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 95%;
    margin: 0 auto;
    color: #ffffff;
    @media only screen and (max-width: 1024px) {
      flex-direction: column;
      align-items: center;
      margin-top: 32px;
    }
    .snackles-footer-zuru-text {
      font-size: 14px;
      line-height: 27px;
      font-family: ${FONT_BODY};
      font-styl
      font-weight: 500;
      margin: 0 0 15px;
      text-transform: capitalize;
      @media only screen and (max-width: 1024px) {
        margin: 0 auto 23px;
        text-align: center;
        order: 1;
        width: 65%;
      }
      @media only screen and (max-width: 768px) {
        width: 30%;
      }
    }
    .snackles-footer-image {
      @media only screen and (max-width: 1024px) {
        order: 3;
      }
      img {
        display: block;
      }
    }
    .snackles-footer-link {
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 27px;
      font-family: ${FONT_BODY};
      font-weight: 500;
      margin-bottom: 15px;
      text-transform: capitalize;
      @media only screen and (max-width: 1024px) {
        order: 2;
      }
      @media only screen and (max-width: 768px) {
       margin-bottom: 0;
      }
      span {
        margin: 0 5px;
      }
    }
  }
  .snackles-footer-title {
    font-family: boorsok;
    font-size: 125px;
    font-weight: 400;
    line-height: 0.85;
    letter-spacing: 0px;
    text-align: center;
    color: #ffffff;
    width: 50%;
    margin: 0 auto;
    @media only screen and (max-width: 1280px) {
      font-size: 120px;
      line-height: 100px;
    }
    @media only screen and (max-width: 1024px) {
      font-size: 78px;
      line-height: 1;
      width: 55%;
    }
    @media only screen and (max-width: 768px) {
      font-size: 54px;
      width: 70%;
    }
    @media only screen and (max-width: 576px) {
      width: 90%;
    }
  }
  .snackles-footer-text {
    margin-top: 46px;
    font-family: ${INTER_FONT};
    font-size: 18px;
    line-height: 1.44;
    color: #ffffff;
    display: flex;
    align-items: center;
    span {
      border-left: 1px solid #ffffff;
      margin: 0 23px;
      height: 45px;
    }
  }
  .snackles-footer-link-content {
    margin-right: 15px !important;
    font-size: 14px;
  }
  .footer-link-container {
    display: flex;
    span {
      margin: 0 4px;
    }
  }
`;

function SnacklesSlider({ data, pageIban }: Props) {
  const [openLinkModel, setOpenLinkModel] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const mainPageData = mainData[pageIban];
  const defaultIban = process.env.GATSBY_DEFAULT_IBAN_REGION;

  const formatUrlPath = () => (pageIban === defaultIban ? "/" : `/${pageIban}`);

  useEffect(() => {
    let isMounted = true;
    if (isBrowser()) {
      const handleScroll = () => {
        const scrollPosition = window.scrollY;
        if (isMounted) {
          setIsScrolled(scrollPosition > 0);
        }
      };
      window.addEventListener("scroll", handleScroll);
      return () => {
        isMounted = false;
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  const openLinkModelPopUp = () => {
    setIsAnimating(true);
    setOpenLinkModel(true);
  };

  const closeLinkModelPopUp = () => {
    setIsAnimating(false);
    setTimeout(() => {
      setOpenLinkModel(false);
    }, 600);
  };

  const handleBtnClick = () => {
    const videoElement = document.getElementById("snackles-carousel");
    videoElement?.scrollIntoView({ behavior: "smooth" });
  };
  // used this type of structure because of the Hero section its take properties like this
  const snacklesVideo = {
    video: {
      url: "https://player.vimeo.com/video/839568264?background=1",
    },
  };
  if (data == null) return null;

  return (
    <>
      <SnacklesPage>
        <SnacklesContainer isScrolled={isScrolled}>
          <span className="snackles-header-text" onClick={openLinkModelPopUp}>
            {mainPageData.title}
          </span>
          <img className="snackles-logo" src={snacklesLogo} alt="snackles-logo" />
          <span className="snackles-header-text">
            <a href={collection} target="_blank">
              {mainPageData.collectorsGuide}
            </a>
          </span>
          {openLinkModel && (
            <>
              <LinkModelOverlay className={isAnimating ? "open" : "close"} />
              <LinkModelPopup className={isAnimating ? "open" : "close"}>
                <div className="link-model-title">{mainPageData.modalTitle}</div>
                <div className="link-model-content">{mainPageData.modalContent}</div>
                <div className="link-model-footer">
                  <div className="link-model-footer-item" onClick={closeLinkModelPopUp}>
                    {mainPageData.btnText1}
                  </div>
                  <div className="link-model-footer-item">
                    <A href={formatUrlPath()} className="white-border">
                      <span className="link-model-text">{mainPageData.btnText2}</span>
                    </A>
                  </div>
                </div>
                <div className="link-model-footer-img">
                  <img src={snacklesPopupImage} rel="preload" alt="popup-image" />
                </div>
              </LinkModelPopup>
            </>
          )}
        </SnacklesContainer>
        <Container height="100vh" position="relative" width="100%" className="header-cover-img">
          <img src={snacklesCover} alt="header-cover-image" />
        </Container>
        <HeaderTextContainer>
          <div className="snakcles-container-text">{mainPageData.mainTitle}</div>
          <div className="snackles-container-sub-text" onClick={handleBtnClick}>
            {mainPageData.mainBtnText}
          </div>
        </HeaderTextContainer>
        <Marquee pageIban={pageIban} />
        <Container backgroundRGBColor="#E9EAEB">
          <SnacklesCarousel data={data} pageIban={pageIban} />
        </Container>
        <Hero useFullHeight data={snacklesVideo} />
        <Container backgroundRGBColor="#E9EAEB">
          <SnacklesSecondaryCarousel data={data} pageIban={pageIban} />
        </Container>

        <SnacklesFooterContainer
          className="snackles-mobile-wrapper"
          flexDirection="column"
          alignItems="center"
          width="100%"
        >
          <div className="snackles-footer-title">{mainPageData.footerTitle}</div>
          <div className="snackles-footer-text">
            <a target="_blank" href="https://www.tiktok.com/@snacklesofficial">
              TIKTOK
            </a>
            <span></span>
            <a target="_blank" href="https://www.instagram.com/zuru.toys/">
              INSTAGRAM
            </a>
          </div>

          <div className="snackles-footer-content-container">
            <div className="snackles-footer-zuru-text">{mainPageData.footerText}</div>
            <div className="snackles-footer-image">
              <img src={snacklesBottomImg} alt="footer-image" />
            </div>
            <div className="snackles-footer-link">
              {mainPageData.footerLinkText.map((link, index) => (
                <>
                  {index > 0 && " / "}
                  <a href={link.url}>
                    <span>{link.text}</span>
                  </a>
                </>
              ))}
            </div>
          </div>
        </SnacklesFooterContainer>
      </SnacklesPage>
    </>
  );
}
export default SnacklesSlider;
