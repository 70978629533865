import React, { useState } from "react";
import styled from "styled-components";
import { PlayButton } from "@util/assets";
import { Eurostile_BQ, SECONDARY_COLOR } from "@util/constants";
//@ts-ignore
import overlayImg from "@static/assets/overlay.png";
//@ts-ignore
import overlayMobileImg from "@static/assets/overlay-mobile.png";

interface Props {
  foregroundOpen?: boolean;
  src?: string;
}

const VideoWrapper = styled.div<{ foregroundOpen?: boolean }>`
  position: absolute;
  min-height: 100vh;
  height: 56.25vw;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  iframe {
    width: 100%;
    height: 56.25vw;
    min-height: 100vh;
    min-width: 100%;
    border: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    ${({ foregroundOpen }) => foregroundOpen && `min-width: 0px`}
  }
`;

const Overlay = styled.div<{ foregroundOpen?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-image: url(${overlayImg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @media (max-width: 576px) {
    background-image: url(${overlayMobileImg});
  }
  .overlay-text {
    font-size: 52px;
    color: ${SECONDARY_COLOR};
    font-weight: 500;
    line-height: 49px;
    font-family: ${Eurostile_BQ};
    position: absolute;
    top: 60px;
    left: 60px;
    @media (max-width: 576px) {
      font-size: 32px;
      line-height: 30px;
      top: 30px;
      left: 30px;
    }
  }
`;

function InsanityVideo({ foregroundOpen, src }: Props) {
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayButtonClick = () => {
    setIsPlaying(true);
  };

  return (
    <VideoWrapper foregroundOpen={foregroundOpen}>
      {!isPlaying && (
        <Overlay foregroundOpen={foregroundOpen} >
          <div className="overlay-text">HOW TO<br />BUILD INSANE<br />COMBOS</div>
          <PlayButton onClick={handlePlayButtonClick} />
        </Overlay>
      )}
      {isPlaying && (
        <iframe
          src={src}
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          frameBorder="0"
          allowFullScreen={true}
          webkitallowFullScreen={true}
          mozallowFullScreen={true}
        />
      )}
    </VideoWrapper>
  );
}

export default InsanityVideo;
