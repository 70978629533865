import styled from "styled-components";
import { BOORSOK_FONT, FONT_BODY } from "@util/constants";

export const SnacklesSwiperContainer = styled.div`
  width: 100%;
  margin: 150px auto 135px auto;
  text-align: center;
  position: relative;
  @media (max-width: 1240px) {
    margin: 200px auto 100px;
  }

  @media (max-width: 768px) {
    margin: 150px auto 50px;
  }
  @media (max-width: 576px) {
    margin-bottom: 65px;
  }
  @media (max-width: 320px) {
    margin: 225px auto 50px;
  }
  .swiper-overlay-text {
    font-family: ${BOORSOK_FONT};
    font-size: 125px;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 84%;
    color: #e4007e;
    text-transform: uppercase;
    position: absolute;
    width: 62%;

    @media (max-width: 1880px) {
      width: 65%;
    }
    @media (max-width: 1660px) {
      font-size: 100px;
      width: 75%;
    }
    @media (max-width: 1240px) {
      font-size: 90px;
    }
    @media (max-width: 1024px) {
      width: 80%;
      font-size: 80px;
    }
    @media (max-width: 768px) {
      font-size: 72px;
      line-height: 79%;
      width: 80%;
      // transform: translate(-50%, -80%);
    }
    @media (max-width: 400px) {
      font-size: 60px;
    }
  }
  .slide-content {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: auto;
    margin-top: 10px;

    @media (max-width: 1024px) {
      width: 80%;
    }
    @media (max-width: 576px) {
      width: 100%;
    }
  }
  .secondary-slide-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: auto;
    margin-top: 10px;
    color: #e4007e;
    gap: 50px 0;
    font-family: ${BOORSOK_FONT};
    @media (max-width: 1024px) {
      width: 90%;
    }
    @media (max-width: 991px) {
      width: 100%;
    }

    .slider-title-container {
      font-family: ${BOORSOK_FONT};
      font-size: 64px;
      line-height: 64px;
    }
    .slider-text-container {
      display: flex;
      justify-content: center;
      align-item: center;
      gap: 0 80px;
      @media (max-width: 991px) {
        flex-direction: column;
        gap: 30px 0;
      }
      .slider-text-content {
        color: #e4007e;
        font-family: ${FONT_BODY};
        font-weight: 600;
        font-size: 26px;
        line-height: 1.2;
        text-transform: capitalize;
        @media (max-width: 991px) {
          font-size: 22px;
        }
      }
      p {
        font-weight: 400 !important;
      }
    }
  }

  .swiper-sub-container {
    width: 85%;
    margin: 0 auto;
    position: relative;
    @media (max-width: 576px) {
      width: 100%;
    }
  }
  .swiper-sub-container:after,
  .swiper-sub-container:before {
    content: "";
    position: absolute;
    top: 0;
    width: 60px;
    height: 100%;
    margin: 0;
    padding: 0;
    z-index: 10;
    pointer-events: none;
  }

  .swiper-sub-container:after {
    right: 0px;
    background-image: linear-gradient(90deg, hsla(0, 0%, 100%, 0) 0, #e9eaeb);
  }

  .swiper-sub-container:before {
    background-image: linear-gradient(-90deg, hsla(0, 0%, 100%, 0) 0, #e9eaeb);
    left: 0px;
  }
`;